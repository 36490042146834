<template>
  <div class="footer-side" :class="className">
    <div>{{ title }}</div>
    <Icons class="footer-side__icons" :icons="icons" />
  </div>
</template>

<script>
import Icons from '@/components/Icons/Icons.vue'

export default {
  name: 'FooterSide',
  props: {
    className: {
      type: String,
      default: ''
    },
    icons: {
      type: Array,
      default: () => []
    },
    title: {
      type: String,
      default: ''
    }
  },
  components: {
    Icons
  }
}
</script>

<style lang="scss" src="./FooterSide.scss" />
