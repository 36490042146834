<template>
  <div class="container">
    <div class="form">
      <h2 class="form__header">Заполняйте форму<br> и становитесь нашим следующим гостем!</h2>
      <form action="/mail.php" class="form__form">
        <input
          type="text"
          class="form__field"
          id="name"
          name="name"
          placeholder="Кто вы и чем занимаетесь"
          v-model="name"
        >
        <input
          type="text"
          class="form__field"
          id="themes"
          name="themes"
          placeholder="Какие темы хотите обсудить"
          v-model="themes"
        >
        <input
          type="text"
          class="form__field"
          id="contacts"
          name="contacts"
          placeholder="Как с вами связаться"
          v-model="contacts"
        >
      </form>
      <button type="submit" class="button form__button" @click.prevent="postData">{{ sendingForm ? 'Отправка формы...' : 'Отправить форму' }}</button>
    </div>
  </div>
</template>

<script>
const SEND_URL = '/mail.php'

export default {
  name: 'FormComponent',
  data () {
    return {
      name: '',
      themes: '',
      contacts: '',
      sendingForm: false
    }
  },
  methods: {
    async postData () {
      if (this.sendingForm) {
        return
      }

      const postData = {
        name: this.name,
        themes: this.themes,
        contacts: this.contacts
      }

      if (!postData.name || !postData.themes || !postData.contacts) {
        this.$swal.fire({
          title: 'Заполните все поля формы!!',
          icon: 'warning'
        })

        return
      }

      this.sendingForm = true
      try {
        const res = await fetch(SEND_URL, {
          method: 'post',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(postData)
        })
        if (!res.ok) {
          const message = `An error has occured: ${res.error} - ${res.message}`
          this.$swal.fire({
            title: 'Ошибка!',
            icon: 'error',
            text: message
          })
          throw new Error(message)
        }
        this.$swal.fire({
          title: 'Данные отправлены!',
          icon: 'success'
        })

        this.sendingForm = false
      } catch (err) {
        console.log(err)
        this.$swal.fire({
          title: 'Ошибка!',
          icon: 'error',
          text: 'Даныые не отправлены'
        })
        this.sendingForm = false
      }
    }
  }
}
</script>

<style lang="scss" src="./Form.scss" scoped />
