<template>
  <div class="icons">
    <a :href="icon.url" class="icons__icon-container" v-for="icon in icons" :key="icon.url">
      <img
        :src="require(`@/assets/img/icons/${icon.icon}.svg`)"
        alt="icon"
        class="icons__icon"
      >
      <img
        :src="require(`@/assets/img/icons/${icon.icon}-hover.svg`)"
        alt="icon"
        class="icons__icon_hover"
      >
    </a>
  </div>
</template>

<script>
export default {
  name: 'FormComponent',
  props: {
    icons: {
      type: Array,
      default: () => []
    }
  }
}
</script>

<style lang="scss" src="./Icons.scss" scoped />
