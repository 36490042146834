<template>
  <div class="more" :class="className">
    <a href="#">Смотрите все выпуски</a>
    <a href="#" class="more__icon-container">
      <img src="@/assets/img/arrow.svg" alt="" class="more__icon" />
      <img src="@/assets/img/arrow-hover.svg" alt="" class="more__icon more__icon_hover" />
    </a>
  </div>
</template>

<script>
export default {
  name: 'MoreComponent',
  props: {
    className: {
      type: String,
      default: ''
    }
  }
}
</script>

<style lang="scss" src="./More.scss" />
